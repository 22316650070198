<app-button-group
  *ngIf="slides.enabled"
  title="Themes"
  class="h-100 w-100"
  [(selectedValue)]="slides.active"
>
  <button
    *ngFor="let $slide of slides.slides; index as $index"
    [buttonGroupItem]="$index"
    class="slide-item csz-text fs-3"
  >
    <img class="theme-background" [themeSlideBackground]="$slide" alt="Theme Background">
    <div class="theme-title" [themeSlideTitle]="$slide">{{$slide.title}}</div>
  </button>
</app-button-group>
