<app-button-group
  *ngIf="guessing.enabled"
  title="Guesses"
  class="h-100 w-100"
  [(selectedValue)]="match.guesses.selected"
>
  <button
    *ngFor="let $guess of guessing.previews; index as $index"
    [buttonGroupItem]="$index"
    class="guess-item csz-text fs-3"
  >{{$guess.text}}</button>
</app-button-group>
