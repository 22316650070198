<div class="main-grid flex-fill">
  <!-- TOOLBAR -->
  <div class="toolbar d-flex flex-nowrap align-items-stretch h-size-15 px-2 py-2">
    <!-- VIEW CHOOSER -->
    <app-button-group title="View" [(selectedValue)]="match.activeView">
      <button id="slateButton" buttonGroupItem="slate" title="Show Slate">
        <img [src]="match.logoUrl" class="w-100 h-100 image-fit p-2" alt="Show Slate">
      </button>
      <button buttonGroupItem="scoreboard" title="Scoreboard" class="p-2">
        <img src="/assets/scoreboard.png" class="w-100 h-100 image-cover rounded" alt="Scoreboard">
      </button>
      <button buttonGroupItem="guesses" title="Guess" *ngIf="viewAvailable('guesses')">
        <img src="/assets/guess.png" class="w-100 h-100 image-fit p-2" alt="Guess">
      </button>
      <button buttonGroupItem="themes" title="Themes" *ngIf="viewAvailable('themes')">
        <i class="bi-card-image"></i>
      </button>
    </app-button-group>

    <div class="flex-fill"></div>

    <app-match-clock></app-match-clock>
  </div>

  <app-score [editable]="true"></app-score>
  <ng-container [ngSwitch]="match.activeView">
    <app-theme-slide-selector *ngSwitchCase="'themes'"></app-theme-slide-selector>
    <app-guess-selector *ngSwitchDefault></app-guess-selector>
  </ng-container>
  <app-rounds [editable]="true"></app-rounds>
</div>

<app-sidebar></app-sidebar>
<app-profile-picker *ngIf="!match.profileSelected"></app-profile-picker>
