<div>
  <label class="form-label">Logo</label>
  <app-icon-selector [icons]="profiles.logos" [(selectedIcon)]="profileLogo"></app-icon-selector>
</div>

<div class="d-none">
  <label class="form-label" for="profileSocial">Social</label>
  <input
    id="profileSocial"
    class="form-control"
    [(ngModel)]="match.social"
    placeholder="(FB)(IG) @account (TW) @account"
    title="Enter text as you want it shown. Use (FB), (IG) and (TW) for social logos."
  >
</div>

<div class="form-check">
  <input class="form-check-input" type="checkbox" id="disableRoundFlyby" [(ngModel)]="match.profile.disableRoundFlyby">
  <label class="form-check-label" for="disableRoundFlyby">
    Disable Round Animation
  </label>
</div>

<div class="form-check">
  <input class="form-check-input" type="checkbox" id="disableTeamFlyby" [(ngModel)]="match.profile.disableTeamFlyby">
  <label class="form-check-label" for="disableTeamFlyby">
    Disable Winning Team Animation
  </label>
</div>

<div class="d-flex-column gap-1">
  <button class="btn btn-primary form-control" (click)="save()" *ngIf="!match.profile.builtin">
    Save
  </button>
  <button class="btn btn-success form-control" (click)="saveAs()">
    Save As...
  </button>
  <button class="btn btn-danger form-control" (click)="delete()" [disabled]="profiles.profiles.length === 1">
    Delete
  </button>
</div>

<div class="text-secondary bg-dark rounded p-2">
  Build: {{buildInfo.buildTime | date : 'MMM d yyyy, h:mmaa' }}
</div>
