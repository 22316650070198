<ng-container *ngIf="slide as $slide">
  <div class="heading csz-bold-text">{{$slide.title}}</div>

  <div class="body flex-fill">
    <div
      *ngFor="let $part of $slide.parts"
      [ngClass]="partClasses($part)"
    >
      <div *ngFor="let $answer of $part.answers" class="guess" [innerHTML]="$answer"></div>
    </div>
  </div>
</ng-container>
