<ng-template #profile let-$profile="$profile">
  <div class="d-inline-block">
    <img class="profile-logo" src="/assets/logos/{{$profile.logo}}" alt="logo">
    <span class="px-2">{{$profile.name}}</span>
  </div>
</ng-template>

<div class="settings-form flex-fill">
  <div class="w-100 pe-1" style="max-height: 100px">
    <!-- PROFILE -->
    <div class="dropdown">
      <button class="btn btn-light dropdown-toggle mb-3 form-control" type="button" id="profileButton"
              data-bs-toggle="dropdown" aria-expanded="false">
        <ng-container [ngTemplateOutlet]="profile" [ngTemplateOutletContext]="{$profile: match.profile}"></ng-container>
      </button>
      <ul class="dropdown-menu" aria-labelledby="profileButton">
        <li *ngFor="let $profile of profiles.profiles">
          <button class="dropdown-item" (click)="match.profile = $profile">
            <ng-container [ngTemplateOutlet]="profile" [ngTemplateOutletContext]="{$profile}"></ng-container>
          </button>
        </li>
      </ul>
    </div>

    <!-- TABS -->
    <ul class="nav nav-tabs nav-fill mb-2">
      <li class="nav-item" *ngFor="let $tab of tabs">
        <a class="nav-link"
           [class.active]="activeTab === $tab"
           aria-current="page"
           (click)="activeTab = $tab">
          {{$tab | titlecase}}</a>
      </li>
    </ul>

    <div [ngSwitch]="activeTab">
      <app-match-teams-editor *ngSwitchCase="'teams'"></app-match-teams-editor>
      <app-match-rounds-editor *ngSwitchCase="'rounds'"></app-match-rounds-editor>
      <app-match-profile-editor *ngSwitchCase="'profile'"></app-match-profile-editor>
    </div>

  </div>
</div>
