<table class="table table-dark table-sm">
  <tr>
    <th>UA:</th>
    <td>{{userAgent}}</td>
  </tr>
  <tr>
    <th>App:</th>
    <td>{{platform.isApp}}</td>
  </tr>
</table>
