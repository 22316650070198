<ng-template #team let-$team let-$edit="edit">
  <div class="team csz-bg-{{$team.type}}">
    <div class="score w-100">
      <ng-container *ngIf="editable">
        <!-- DECREMENT SCORE -->
        <button class="score-button btn hover-highlight" (click)="$team.score = $team.score - 1">
          <i class="bi-dash-circle"></i>
        </button>
        <!-- CURRENT SCORE -->
        <div class="score-value csz-bold-text clickable hover-highlight" (click)="editScore($team)">
          {{$team.score}}
        </div>
        <!-- INCREMENT SCORE -->
        <button class="score-button btn hover-highlight" (click)="$team.score = $team.score + 1">
          <i class="bi-plus-circle"></i>
        </button>
      </ng-container>

      <ng-container *ngIf="!editable">
        <div class="score-value csz-bold-text">
          {{$team.score}}
        </div>
      </ng-container>
    </div>

    <div class="team-name csz-bold-text">
      {{$team.name}}
    </div>
  </div>
</ng-template>

<ng-container
  [ngTemplateOutlet]="team"
  [ngTemplateOutletContext]="{$implicit: match.teams.blue}"
></ng-container>
<ng-container
  [ngTemplateOutlet]="team"
  [ngTemplateOutletContext]="{$implicit: match.teams.red}"
></ng-container>
<ng-container
  *ngIf="editable && match.teams.optionalEnabled"
  [ngTemplateOutlet]="team"
  [ngTemplateOutletContext]="{$implicit: match.teams.optional}"
></ng-container>
