<app-flyby #teamFlyby *ngIf="!match.profile.disableTeamFlyby" [direction]="teamDirection">
  <ng-container *ngIf="match.winningTeam as $team; else tiedScore">
    <img *ngIf="$team.hasLogo; else teamName" class="team-name" [src]="$team.logoPath" [alt]="$team.name">
    <ng-template #teamName>
      <div class="team-name team-{{$team.type}}">{{$team.name}}</div>
    </ng-template>
  </ng-container>
  <ng-template #tiedScore>
    <div class="team-name">Tied Score!!!</div>
  </ng-template>
</app-flyby>

<app-live-scoreboard
  class="view"
  [class.active]="showScore"
></app-live-scoreboard>

<app-live-slate
  class="view"
  [class.active]="showSlate"
></app-live-slate>

<app-live-guesses
  *ngIf="match.guesses.game"
  class="view"
  [class.active]="showGuesses"
></app-live-guesses>

<app-live-themes
  *ngIf="match.themeSlides.enabled"
  class="view"
  [class.active]="showThemes"
></app-live-themes>

<!--
FULL-SCREEN BUTTON
-->
<button *ngIf="fullScreen.canEnter"
        class="fullscreen-button btn text-light"
        (click)="enterFullScreen()"
>
  <i class="bi-fullscreen"></i>&nbsp;&nbsp;Double-Click
</button>
